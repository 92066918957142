/* eslint-disable import/no-cycle */
import {
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  Typography,
  Stack,
  // Button,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import RepresentativeDetail from './RepresentativesInfo';
import RepresentativesForm from './RepresentativesForm';
import useLocales from '../../../../../../lib/hooks/useLocales';
import { useGetRepresentatives } from '#/api/representativeQueries';
// import Iconify from '#/components/shared/ui/Iconify';
import PriorityCheck from '#/components/pages/LivingWill/Steps/content/Representatives/PriorityCheck';
import { RHFDatePicker } from '#/components/shared/hook-form';

const Representative = () => {
  const {
    watch,
    formState: { errors },
    setValue,
  } = useFormContext();

  const { data: representatives, isLoading } = useGetRepresentatives();

  const [addFor, setAddFor] = useState<string | null>();
  const [addMore, setAddMore] = useState(false);
  const [repInformed, setRepInformed] = useState(
    watch('representative_inform_date') !== ''
  );

  const { translate } = useLocales();

  const handleAddMore = () => {
    setAddMore((prev) => !prev);
  };

  const handleAddFor = (rep: string) => {
    setAddFor(rep);
  };

  const isInitial = representatives?.length === 0;

  useEffect(() => {
    if (addFor && representatives) {
      setValue(addFor, representatives[representatives.length - 1].id);
    }
  }, [addFor, representatives]);

  return (
    <Card
      sx={{
        m: 1,
        p: 2,
      }}
    >
      <Typography variant="subtitle1" mb={2}>
        {String(translate('livingWill.stepTwo.introParagraph'))}
      </Typography>
      <Typography variant="body1" mb={2}>
        {String(translate('livingWill.stepTwo.helperParagraph'))}
      </Typography>
      {isInitial ? (
        <RepresentativesForm />
      ) : (
        <Box>
          {!addMore ? (
            <>
              <Stack
                direction={{
                  xs: 'column',
                  md: 'row',
                }}
                justifyContent="space-between"
                spacing={3}
              >
                <Box
                  sx={{
                    width: {
                      xs: '100%',
                      md: '50%',
                    },
                    maxHeight: 600,
                    overflow: 'auto',
                    scrollbarWidth: 'none',
                  }}
                >
                  {representatives?.map((representative) => (
                    <Stack key={representative.id}>
                      <RepresentativeDetail
                        representative={representative}
                        isLoading={isLoading}
                      />
                    </Stack>
                  ))}
                </Box>
                <PriorityCheck
                  watch={watch}
                  representatives={representatives}
                  setValue={setValue}
                  addNew={handleAddMore}
                  handleAddFor={handleAddFor}
                />
              </Stack>

              {/* <Button */}
              {/*  variant="contained" */}
              {/*  size="small" */}
              {/*  color="secondary" */}
              {/*  sx={{ */}
              {/*    my: 2, */}
              {/*  }} */}
              {/*  startIcon={<Iconify icon="mdi:plus-circle" />} */}
              {/*  onClick={handleAddMore} */}
              {/* > */}
              {/*  <Typography variant="caption"> */}
              {/*    {String(translate('global.addAnother'))} */}
              {/*  </Typography> */}
              {/* </Button> */}
              <Stack
                direction={{
                  xs: 'column',
                  sm: 'row',
                }}
                alignItems="center"
                sx={{ mt: 3 }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      value={repInformed}
                      checked={repInformed}
                      onChange={() => {
                        setRepInformed((prev) => !prev);
                        setValue('repInformed', !repInformed);
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                      sx={{
                        flexDirection: 'row-reverse',
                      }}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {String(
                        translate('livingWill.stepTwo.contactConfirmation')
                      )}
                    </Typography>
                  }
                />
                {repInformed && (
                  <Stack alignItems="center">
                    <RHFDatePicker
                      name="representative_inform_date"
                      label={String(
                        translate('global.formLabels.rep_inform_date')
                      )}
                      disableFuture
                    />
                  </Stack>
                )}
              </Stack>
            </>
          ) : (
            <RepresentativesForm handleAddMore={handleAddMore} />
          )}
        </Box>
      )}
      {errors.primary_representative || errors.secondary_representative ? (
        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
          {String(translate('validations.rep_selection'))}
        </Typography>
      ) : null}
    </Card>
  );
};

export default Representative;
