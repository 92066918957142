import { yupResolver } from '@hookform/resolvers/yup';
import {
  Stack,
  Stepper,
  Step,
  StepLabel,
  Card,
  Button,
  Typography,
  Divider,
  Container,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import React, { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { QontoConnector } from './Steps/styles';
import QontoStepIcon from './Steps/QontoStepIcon';
import { FormProvider } from '#/components/shared/hook-form';
import { stepsData } from './Steps/content/stepsData';
import { ILivingWillForm } from '#/types/livingWill';
import useLocales from '../../../lib/hooks/useLocales';
import useStepper from '../../../lib/hooks/useStepper';
import EnoughForNowModal from '#/components/shared/ui/EnoughForNowModal';
import {
  schemaValues,
  stepFourSchema,
  stepOneSchema,
  stepThreeSchema,
  stepTwoSchema,
} from '#/components/pages/LivingWill/schemaValidation';
import useAuth from '#/hooks/useAuth';
import {
  invalidateServicesStatsQueries,
  useGetServiceForm,
  useStartService,
} from '#/api/servicesQueries';
import Iconify from '#/components/shared/ui/Iconify';
import { useUpdateProfile } from '#/api/userQueries';
import useServiceStats from '#/hooks/useServiceStats';
import MarkAsDone from '#/components/shared/mark-as-done';
import UploadSignedDoc from '#/components/shared/UploadSignedDoc';

const LivingForm = () => {
  const { user, refetch } = useAuth();
  const { living_will, power_of_attorney } = useServiceStats();
  const { state } = useLocation();

  const { mutateAsync: updateProfile } = useUpdateProfile();

  const { data: dbFormValues } = useGetServiceForm('living-will');

  const { mutateAsync: initialiseLivingWill } = useStartService('livingwill');

  const [openEnoughNow, setOpenEnoughNow] = useState(false);

  const handleOpenEnoughNow = () => setOpenEnoughNow(true);

  const handleCloseEnoughNow = () => setOpenEnoughNow(false);

  const { handleNext, handleBack, activeStep, setActiveStep } = useStepper(
    !living_will?.service?.completed,
    'living_will',
    stepsData
  );

  const { translate } = useLocales();
  const navigate = useNavigate();

  const formSchemas = [
    stepOneSchema,
    stepTwoSchema,
    stepThreeSchema,
    stepFourSchema,
  ];

  const defaultValues = useMemo(
    () => schemaValues(user, dbFormValues, activeStep),
    [user, dbFormValues, activeStep]
  );

  const methods = useForm<ILivingWillForm>({
    resolver: formSchemas[activeStep]
      ? yupResolver(formSchemas[activeStep] as any)
      : undefined,
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
    watch,
  } = methods;

  const submitForm = async ({ data = watch() }: { data: any }) => {
    const savingToast = toast(String(translate('global.saving')), {
      icon: <Iconify icon="arcticons:fastsave" />,
    });

    const reqData = {
      ...data,
      ...Object.fromEntries(
        Object.entries(data).map(([key, value]) => [
          key,
          value === '' ? null : value,
        ])
      ),
      date_of_birth: data.date_of_birth
        ? new Date(data.date_of_birth).toISOString().split('T')[0]
        : null,
      representative_inform_date: data.representative_inform_date
        ? new Date(data.representative_inform_date).toISOString().split('T')[0]
        : null,
    };

    if (activeStep === 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      await updateProfile({
        civil_status: reqData?.civil_status,
        date_of_birth: reqData?.date_of_birth,
        mobile_phone: user?.mobile_phone,
        first_name: defaultValues.first_name,
        last_name: defaultValues.last_name,
        address: {
          street: watch('street'),
          house_number: watch('house_number'),
          zip_code: watch('zip_code'),
          city: watch('city'),
          country: watch('country'),
        },
      }).then(() => refetch());
    }
    await initialiseLivingWill(reqData).then(() => {
      toast.dismiss(savingToast);
      handleNext();
      invalidateServicesStatsQueries.getServiceForm('living-will');
      invalidateServicesStatsQueries.getServicesStats();
    });
    scrollToTop();
  };

  const onSubmit = async (data: any) => {
    await submitForm({ data });
  };

  const translatedStepsData = stepsData?.map((step, i) => ({
    ...step,
    label: translate(`livingWill.stepperInfo.${i}.label`),
    title: translate(`livingWill.stepperInfo.${i}.title`),
  }));

  const shortPainFree =
    activeStep === 0 || activeStep === 1 || activeStep === 2;

  const madeIt = activeStep === 3;

  // const detailedLivingWill = activeStep === 3;

  useEffect(() => {
    if (dbFormValues || activeStep) {
      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [dbFormValues, activeStep]);

  useEffect(
    () => () => {
      const submit = async () => {
        await submitForm({ data: watch() });
      };

      submit().then((r) => r);
    },
    // eslint-disable-next-line
    []
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const hasNoPOA = !power_of_attorney?.service?.completed;

  if (
    state?.step === 'markAsDone' ||
    state?.step === 'history' ||
    state?.step === 'uploadDocument'
  ) {
    return (
      <MarkAsDone
        serviceName="livingwill"
        serviceInvalidateKey="living-will"
        documentType="livingWill"
      />
    );
  }

  return (
    <Container maxWidth="lg">
      {shortPainFree && (
        <Typography variant="h2" mb={5} textAlign="center">
          {String(translate('livingWill.mainHeaders.shortPainFree'))}
        </Typography>
      )}
      {madeIt && (
        <Typography variant="h2" mb={5} textAlign="center">
          {String(translate('livingWill.mainHeaders.madeIt'))}
        </Typography>
      )}
      {/* {detailedLivingWill && ( */}
      {/*  <Typography variant="h2" mb={5} textAlign="center"> */}
      {/*    {String(translate('livingWill.mainHeaders.detailedLivingWill'))} */}
      {/*  </Typography> */}
      {/* )} */}
      <Stack sx={{ width: '100%' }} spacing={4}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<QontoConnector />}
            sx={{
              display: {
                xs: 'none',
                sm: 'flex',
              },
            }}
          >
            {translatedStepsData?.map((step, i) => (
              <Step
                key={`step-${step.title}`}
                onClick={() => {
                  if (i < activeStep) {
                    setActiveStep(i);
                  }
                  if (living_will?.service?.completed) {
                    setActiveStep(i);
                  }
                }}
                sx={{
                  ...((i < activeStep || living_will?.service?.completed) && {
                    cursor: 'pointer',
                  }),
                }}
              >
                <StepLabel StepIconComponent={QontoStepIcon}>
                  {String(step.label)}
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          <Card sx={{ p: 3, mt: 4 }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="subtitle1" mb={2}>
                {String(translatedStepsData[activeStep]?.title)}
              </Typography>
              {activeStep !== translatedStepsData.length - 1 && (
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  sx={{ mb: 2 }}
                  onClick={handleOpenEnoughNow}
                >
                  <Typography
                    variant="body1"
                    color="error"
                    sx={{ cursor: 'pointer' }}
                  >
                    {String(translate('global.enoughForNow'))}
                  </Typography>
                </Button>
              )}
            </Stack>
            <Divider
              sx={{
                mb: 3,
              }}
            />
            {translatedStepsData[activeStep]?.content(
              setActiveStep,
              dbFormValues
            )}
            <Divider
              sx={{
                mt: 4,
              }}
            />
            <Stack
              direction={{
                xs: 'column',
                sm: 'row',
              }}
              justifyContent="flex-end"
              spacing={2}
              sx={{
                mt: 3,
              }}
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <Typography>{String(translate('global.back'))}</Typography>
              </Button>
              {activeStep === translatedStepsData.length - 1 && (
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate('/dashboard/home');
                  }}
                  color="success"
                  sx={{
                    color: '#fff',
                  }}
                >
                  <Typography>{String(translate('global.done'))}</Typography>
                </Button>
              )}

              {activeStep !== translatedStepsData.length - 1 && (
                <LoadingButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={isSubmitting}
                >
                  <Typography>{String(translate('global.next'))}</Typography>
                </LoadingButton>
              )}
              {hasNoPOA && activeStep === translatedStepsData.length - 1 && (
                <LoadingButton
                  onClick={async () => {
                    if (activeStep === translatedStepsData.length - 1) {
                      navigate('/dashboard/vorsorgeauftrag');
                    }
                  }}
                  variant="contained"
                >
                  <Typography>
                    {String(translate('global.continueToPOA'))}
                  </Typography>
                </LoadingButton>
              )}
            </Stack>
          </Card>
        </FormProvider>
      </Stack>
      <EnoughForNowModal
        openEnoughNow={openEnoughNow}
        handleCloseEnoughNow={handleCloseEnoughNow}
      />
    </Container>
  );
};
export default LivingForm;
