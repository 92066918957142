import { Typography, Stack, Button, Box, Skeleton } from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useFormContext } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import RepresentativesForm from './RepresentativesForm';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/hooks/useLocales';
import { IRepresentative } from '#/types/representative';
import {
  invalidateRepresentativeQueries,
  useDeleteRepresentative,
} from '#/api/representativeQueries';
import DeleteConfirmationModal from '#/components/pages/LivingWill/Steps/content/Representatives/DeleteConfirmationModal';

type Props = {
  representative: IRepresentative;
  isLoading?: boolean;
  sx?: any;
};

const RepresentativesInfo = ({ representative, sx, isLoading }: Props) => {
  const { watch } = useFormContext();
  const theme = useTheme();

  const { translate } = useLocales();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const handleEditMode = () => {
    setEditMode((prev) => !prev);
  };

  const handleOpenDeleteDialog = () => setOpenDeleteDialog(true);

  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const { id, first_name, last_name, address } = representative;

  const { mutateAsync: deleteRepresentative } = useDeleteRepresentative(id);

  const handleDelete = async () => {
    await deleteRepresentative(id)
      .then(() => {
        toast.success(
          String(translate('toast_notifications.success.representative_delete'))
        );
        invalidateRepresentativeQueries.getRepresentatives();
      })
      .catch(() => {
        toast.error(
          String(translate('toast_notifications.error.representative_delete'))
        );
      });
  };

  const isPrimary = watch('primary_representative') === representative.id;

  const isSecondary = watch('secondary_representative') === representative.id;

  return (
    <Box
      sx={{
        width: 1,
        marginY: 1.5,
      }}
    >
      {editMode ? (
        <RepresentativesForm
          representative={representative}
          isEdit={editMode}
          handleEditMode={handleEditMode}
        />
      ) : (
        <Box
          sx={{
            my: 1,
            ...sx,
            display: 'flex',
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
            justifyContent: 'space-between',
            alignItems: {
              xs: 'flex-start',
              sm: 'center',
            },
            border: `${isPrimary || isSecondary ? '1px' : '0.5px'} dashed ${
              // eslint-disable-next-line no-nested-ternary
              isPrimary
                ? theme.palette.primary.main
                : isSecondary
                ? 'green'
                : theme.palette.grey[500]
            }`,
            p: 2,
          }}
        >
          <Stack>
            <Typography variant="subtitle1">
              {isLoading ? (
                <Skeleton variant="text" width={100} />
              ) : (
                `${first_name} ${last_name}`
              )}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: {
                  xs: 'column',
                  sm: 'row',
                },
                justifyContent: 'space-between',
                alignItems: {
                  xs: 'flex-start',
                  sm: 'center',
                },
              }}
            >
              <Stack>
                {/* <Typography variant="body2"> */}
                {/*  {String(translate('global.formLabels.country'))}:{' '} */}
                {/*  <span style={{ fontWeight: 600, fontSize: 16 }}> */}
                {/*    {address.country} */}
                {/*  </span> */}
                {/* </Typography> */}

                <Typography variant="body2">
                  {String(translate('global.formLabels.city'))}:{' '}
                  <span style={{ fontWeight: 600, fontSize: 16 }}>
                    {address.city}
                  </span>
                </Typography>
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="text"
                    onClick={handleOpenDeleteDialog}
                    color="error"
                    size="small"
                    startIcon={
                      <Iconify
                        icon="material-symbols:delete-outline"
                        width={16}
                        height={16}
                      />
                    }
                  >
                    <Typography variant="caption">
                      {String(translate('global.delete'))}
                    </Typography>
                  </Button>

                  <Button
                    variant="text"
                    size="small"
                    startIcon={
                      <Iconify
                        icon="material-symbols:edit"
                        width={16}
                        height={16}
                      />
                    }
                    onClick={handleEditMode}
                  >
                    <Typography variant="caption">
                      {String(translate('global.edit'))}
                    </Typography>
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Box>
      )}
      <DeleteConfirmationModal
        openDeleteDialog={openDeleteDialog}
        handleCloseDeleteDialog={handleCloseDeleteDialog}
        handleDelete={handleDelete}
      />
    </Box>
  );
};

export default RepresentativesInfo;
